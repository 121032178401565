import React from 'react';
import Layout from '../../layouts/index';

const AboutUs = () => (
  <Layout bodyClass="page-team">
    <div className="container pt-4 pt-md-10">
      <div className="row justify-content-start">
        <div className="col-12 col-md-8">
          <div className="team">
            <h1 className="title">About Us</h1>
            <div className="content">
              <p>
                Middleton Foods is an agribusiness company situated in the
                bustling city of Nairobi. Our principal business is grain
                milling and merchandising of maize meal and raw materials for
                manufacture of animal feeds. We produce flour for households,
                restaurants and institutions around the country. Our goal is to
                meet our customers’ expectations by producing high quality flour
                that is rich in nutrients. We pride ourselves with having a
                technical team that puts quality of our products at the top of
                their priorities.
              </p>
              <p>
                Our passion lies in bringing happiness to households around the
                country. We are committed kicking out hunger and malnutrition
                from within our borders in line with our national collective
                accord of plenty being found within our borders.
              </p>{' '}
              <p>
                We take pride in a value chain that puts the interests of our
                partners ahead of our own. Our end to end value chain includes
                farmers, transporters, wholesalers, retailers and most
                importantly the final consumer.
              </p>
              <p>On your daily nutrition needs, we’ve got you covered.</p>
            </div>
            <h4>Mission</h4>
            <div className="content">
              <p>
                To provide our customers with food products of the highest
                quality at the most affordable price
              </p>
            </div>
            <h4>Vision</h4>
            <div className="content">
              <p>
                To be the best food company that creates unparalled value for
                all from farm to plate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default AboutUs;
